/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {FeatureSection, FeatureList, Feature, FeatureTitle, FeatureText} from "smooth-doc/components";
import logo from "../images/logo-manifest.webp";
import efficiency from "../images/icon/efficiency.webp";
import security from "../images/icon/file-shield-solid.webp";
import community from "../images/icon/users-between-lines-solid.webp";
import borrowChecker from "../images/icon/borrow.webp";
import llvmIcon from "../images/icon/llvm.webp";
import openSource from "../images/icon/open-source.webp";
import cargoIcon from "../images/icon/cargoIcon.webp";
import motherBoard from "../images/icon/motherboard.webp";
import "../styles/home.css";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "wrapper"
  }, React.createElement("h1", null, React.createElement("img", {
    className: "logo",
    src: logo,
    alt: "rustp logo"
  }), React.createElement(_components.p, null, "Rust Programming"))), "\n", React.createElement("div", {
    className: "feature-wrapper"
  }, React.createElement("h2", {
    className: "feature-caption"
  }, React.createElement(_components.p, null, "Why use ", React.createElement("span", {
    className: "rust"
  }, "Rust? "))), React.createElement(FeatureSection, null, React.createElement(FeatureList, null, React.createElement(Feature, {
    className: "feature-item"
  }, React.createElement("img", {
    className: "feature-image",
    src: efficiency,
    loading: "lazy",
    alt: "Time and memory efficient"
  }), React.createElement(FeatureTitle, {
    class: "FeatureTitle"
  }, "Fast and memory efficient"), React.createElement(FeatureText, {
    className: "feature-text"
  }, React.createElement(_components.p, null, "The programs written in Rust are much faster and memory efficient as compared to other languages in general, as there are no runtime Garbage Collectors. Use of compiler instead of interpreter also boosts performance."))), React.createElement(Feature, {
    className: "feature-item"
  }, React.createElement("img", {
    className: "feature-image",
    src: security,
    loading: "lazy",
    alt: "Memory safe"
  }), React.createElement(FeatureTitle, {
    class: "FeatureTitle"
  }, "Memory Safety"), React.createElement(FeatureText, {
    className: "feature-text"
  }, React.createElement(_components.p, null, "Rust ensures that there are no null pointers, dangling pointers, or data races, unlike C / C++. Moreover, you can not access elements outside of array in Rust. Rust also provides unsafe option for more flexibility."))), React.createElement(Feature, {
    className: "feature-item"
  }, React.createElement("img", {
    className: "feature-image",
    src: borrowChecker,
    loading: "lazy",
    alt: "Borrow Checker"
  }), React.createElement(FeatureTitle, {
    class: "FeatureTitle"
  }, "Borrow Checker"), React.createElement(FeatureText, {
    className: "feature-text"
  }, React.createElement(_components.p, null, "Borrow checker is one of the key features of Rust. Its concept is similar to reference counters. It helps to prevent data races and memory leaks. It is implemented by default in Rust for default ADT like Vectors, Dequeue etc."))), React.createElement(Feature, {
    className: "feature-item"
  }, React.createElement("img", {
    className: "feature-image",
    src: llvmIcon,
    loading: "lazy",
    alt: "Use LLVM Compiler Infrastructure"
  }), React.createElement(FeatureTitle, {
    class: "FeatureTitle"
  }, "Use LLVM Compiler Infrastructure"), React.createElement(FeatureText, {
    className: "feature-text"
  }, React.createElement(_components.p, null, "Rust uses LLVM Compiler Infrastructure, which is also used by C compilers (GCC), C++ compilers, Swift, Python, etc. which helps to optimize the code and provide enhanced security."))), React.createElement(Feature, {
    className: "feature-item"
  }, React.createElement("img", {
    className: "feature-image",
    src: motherBoard,
    loading: "lazy",
    alt: "Low Level Programming Support"
  }), React.createElement(FeatureTitle, {
    class: "FeatureTitle"
  }, "Low Level Programming support"), React.createElement(FeatureText, {
    className: "feature-text"
  }, React.createElement(_components.p, null, "Rust supports Low Level Programming and control over memory, like C and C++. Rust also allows the use of Pointers and References as well as executing Assembly Language Instructions and Embedded device support."))), React.createElement(Feature, {
    className: "feature-item"
  }, React.createElement("img", {
    className: "feature-image",
    src: openSource,
    loading: "lazy",
    alt: "Open Source"
  }), React.createElement(FeatureTitle, {
    class: "FeatureTitle"
  }, "Rust is Open Source"), React.createElement(FeatureText, {
    className: "feature-text"
  }, React.createElement(_components.p, null, "Rust is an Open Source Project supported by independent Rust Foundation. Its source code can by found at ", React.createElement("a", {
    href: "https://github.com/rust-lang",
    target: "_blank"
  }, "Rust's Github Repo "), " and is well documented as well. See ", React.createElement("a", {
    href: "https://en.wikipedia.org/wiki/Rust_(programming_language)",
    target: "_blank"
  }, "Wikipedia page for reference ")))), React.createElement(Feature, {
    className: "feature-item"
  }, React.createElement("img", {
    className: "feature-image",
    src: cargoIcon,
    loading: "lazy",
    alt: "Cargo Package Manager"
  }), React.createElement(FeatureTitle, {
    class: "FeatureTitle"
  }, "Cargo Package Manager"), React.createElement(FeatureText, {
    className: "feature-text"
  }, React.createElement(_components.p, null, "Cargo is the Rust package manager. Cargo downloads the Rust package's dependencies, compiles the packages, makes distributable packages, and helps to share them, on ", React.createElement("a", {
    href: "https://crates.io/",
    target: "_blank"
  }, "crates.io")))), React.createElement(Feature, {
    className: "feature-item"
  }, React.createElement("img", {
    className: "feature-image",
    src: community,
    loading: "lazy",
    alt: "Strong community support"
  }), React.createElement(FeatureTitle, {
    class: "FeatureTitle"
  }, "Strong and active Community"), React.createElement(FeatureText, {
    className: "feature-text"
  }, React.createElement(_components.p, null, "Rust has strong and welcoming community. You can contact Rust devs and other community members via forums, various chat platforms as well as email. See ", React.createElement("a", {
    href: "https://www.rust-lang.org/community",
    target: "_blank"
  }, "Rust Official Community Page.")))))), React.createElement("p", {
    id: "bottom_heading"
  }, " This website is an independent project, and is not under the Rust Foundation. "), React.createElement("a", {
    href: "basic-programs/input-single-number/",
    className: "browse_guides"
  }, React.createElement(_components.p, null, "Browse Guides →"))), "\n", "\n", React.createElement(SEO, {
    title: "Home Page - Rust Programming",
    description: "Providing Guides and ready to use codes in Rust Language for development as well as competitive programming. Rust is fast, memory efficient, memory safe, uses borrow checker, provide Low Level Programming support and is lot more interesting."
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
