import React from "react";
import GATSBY_COMPILED_MDX from "/home/naman/Desktop/rustp/website/pages/index.mdx";
import {graphql} from 'gatsby';
export const pageQuery = graphql`
  query PageTemplate($id: String!) {
    mdx(id: { eq: $id }) {
      fields {
        pageType
        title
      }
    }
  }
`;
function PageTemplate({children}) {
  return children;
}
PageTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
